import { ReactNode } from 'react';
import { transactionDocuments } from 'src/pages/transactions/constants';
import { TransactionStatus } from 'src/redux/types';
import {
	TrackerStepName,
	LogStatusEnum,
	LogTypeEnum,
	PropertyTitleCategory,
	TrackerStepStatus,
	TrackerStageName,
} from 'src/types';

export const trackerSteps: TrackerStepName[] = ['seller', 'property', 'buyer', 'legals'];

export const transactionStatus = {
	completed: 'completed',
	error: 'error',
	inProgress: 'inProgress',
	notStarted: 'notStarted',
	pending: 'pending',
} as const;

export const valueByTransactionStatus: Record<TransactionStatus, number> = {
	notStarted: 0,
	completed: 1,
	inProgress: 2,
	pending: 3,
	error: 4,
};

export const transactionStatusIcon: Record<TransactionStatus, ReactNode> = {
	completed: '🟢',
	error: '🔴',
	inProgress: '🟡',
	notStarted: '⚪️',
	pending: '🟠',
} as const;

export const trackerStepStatus: Record<TrackerStepStatus, string> = {
	completed: 'completed',
	error: 'error',
	inProgress: 'inProgress',
	notStarted: 'notStarted',
	pending: 'pending',
};

export const trackerStage = {
	onboarding: 'onboarding',
	questions: 'questions',
	instruction: 'instruction',
	evidence: 'evidence',
	documents: 'documents',
	listing: 'listing',
	searches: 'searches',
	finance: 'finance',
	contractPack: 'contractPack',
	sourceOfFunds: 'sourceOfFunds',
	enquiries: 'enquiries',
	exchange: 'exchange',
} as const;

export const trackerStages = Object.values(trackerStage);

export const labelByTrackerStage: Record<TrackerStageName, string> = {
	onboarding: 'Onboarding',
	questions: 'Questions',
	instruction: 'Instruction',
	evidence: 'Evidence',
	documents: 'Documents',
	listing: 'Listing',
	searches: 'Searches',
	finance: 'Evidence',
	contractPack: 'Contract pack',
	sourceOfFunds: 'Source of funds',
	enquiries: 'Enquiries',
	exchange: 'Exchange',
} as const;

export const iconByTrackerStepStatus: Record<TrackerStepStatus, string> = {
	completed: '🟢',
	error: '🔴',
	inProgress: '🟡',
	pending: '🟠',
	notStarted: '⚪️',
} as const;

export const labelByTrackerStepName: Record<TrackerStepName, string> = {
	seller: 'Sellers',
	property: 'Property',
	buyer: 'Buyers',
	legals: 'Legals',
};

export const labelByTrackerStepStatus: Record<TrackerStepStatus, string> = {
	completed: 'Completed',
	error: 'Error',
	inProgress: 'In Progress',
	notStarted: 'Not Started',
	pending: 'Delayed',
};

export const propertyTitleDocumentsCategories = {
	register: 'register',
	plan: 'plan',
	conveyance: 'conveyance',
	lease: 'lease',
	transfer: 'transfer',
	subCharge: 'subCharge',
	deed: 'deed',
	charge: 'charge',
	other: 'other',
	abstract: 'abstract',
	agreement: 'agreement',
	assent: 'assent',
	assignment: 'assignment',
	indenture: 'indenture',
	licence: 'licence',
	commonholdCommunityStatement: 'commonholdCommunityStatement',
	memorandumAndArticlesOfAssociation: 'memorandumAndArticlesOfAssociation',
	surrenderOfDevelopmentRight: 'surrenderOfDevelopmentRight',
	terminationDocument: 'terminationDocument',
} as const;

export const labelByPropertyTitleDocumentsCategories: Record<PropertyTitleCategory, string> = {
	register: 'Registers',
	plan: 'Plans',
	conveyance: 'Conveyances',
	lease: 'Leases',
	transfer: 'Transfers',
	subCharge: 'Sub-Charges',
	deed: 'Deeds',
	charge: 'Charges',
	other: 'Other',
	abstract: 'Abstracts',
	agreement: 'Agreements',
	assent: 'Assents',
	assignment: 'Assignments',
	indenture: 'Indentures',
	licence: 'Licences',
	commonholdCommunityStatement: 'Commonhold Community Statements',
	memorandumAndArticlesOfAssociation: 'Memorandum and Articles of Associations',
	surrenderOfDevelopmentRight: 'Surrender of Development Rights',
	terminationDocument: 'Termination Documents',
} as const;

export const propertyDocumentAvailabilityCodes = {
	unavailable: 'UNAVAILABLE',
	immediate: 'IMMEDIATE',
	manual: 'MANUAL',
} as const;

export const propertyTitleDocumentsCategoriesKeys = Object.keys(propertyTitleDocumentsCategories);

export const labelByLogEvent: Record<string, string> = {
	CHANGE_EMAIL_REQUEST: 'Change Email Request',
	INITIAL_INVITATION: 'Initial Transaction Invitation',
	INVITATION: 'Invitation',
	NEW_PROPERTY: 'New Property',
	NEW_TRANSACTION: 'New Transaction',
	NEW_USER: 'New User',
	PENDING_INVITATION: 'Pending Invitation',
	PRINCIPAL_PATH: 'Principal Path',
	REGISTRATION: 'Registration',
	RESEND_INVITATION: 'Resend Invitation',
	RESET_PASSWORD: 'Reset Password',
	TITLE_EXTRACTION: 'Title Extraction',
	TITLE_SELECTION: 'Title Selection',
	VERIFICATION: 'Verification',
	PENDING_VERIFICATION: 'Pending Verification',
	SELL_SIDE_PARTICIPANTS_ONBOARDED: 'All sell-side parties onboarded',
	SELL_PACK_HANDOVER: 'Sell Pack Handover',
	KICK_OFF: 'Buy-side Kick-off',
	SELL_SIDE_COMPLETE: 'Sell Side Complete',
	BUY_SIDE_COMPLETE: 'Buy Side Complete',
	PENDING_PERSONAL_QUESTIONNAIRE: 'Pending Personal Details',
	PENDING_SELLER_QUESTIONS: 'Pending Seller Questions',
	PENDING_BUYER_QUESTIONS: 'Pending Buyer Questions',
	PENDING_GIFTOR_QUESTIONS: 'Pending Giftor Questions',
	AUTOMATION: 'Automation',
	MANUAL_TRIGGER: 'Manual Trigger',
	BUY_SIDE_PARTICIPANTS_ONBOARDED: 'All buy-side parties onboarded',
	// BUYER: transactionDocuments.buyer.name,
	CONTRACT_PACK_SENT: transactionDocuments.contractPackSent.name,
	CYBERCRIME_NOTICE: transactionDocuments.cyberCrimeNotice.name,
	DEEDS_REQUEST: transactionDocuments.deedsRequest.name,
	ADVANCE_ENQUIRIES: transactionDocuments.advanceEnquiries.name,
	FILE_CLOSING_NOTICE: transactionDocuments.fileClosingNotice.name,
	GIFT_DEED: transactionDocuments.giftDeed.name,
	MEMORANDUM_OF_SALE: transactionDocuments.memorandumOfSale.name,
	REPLIES_TO_COMPLETION_INFO: transactionDocuments.repliesToCompletionInfo.name,
	REPLIES_TO_ENQUIRIES: transactionDocuments.repliesToEnquiries.name,
	CIF_BUYERS: transactionDocuments.CIFBuyers.name,
	CIF_SELLERS: transactionDocuments.CIFSellers.name,
	SDLT: transactionDocuments.SDLT.name,
	STANDARD_CONDITIONS_OF_SALE: transactionDocuments.standardConditionsOfSale.name,
	TA10: transactionDocuments.ta10.name,
	TA6: transactionDocuments.ta6.name,
	TA7: transactionDocuments.ta7.name,
	TR1: transactionDocuments.tr1.name,
	TR13: transactionDocuments.tr13.name,
	GET_VERIFIED: 'Get verified',
	CONFIRM_VERIFICATION_RESULTS: 'Confirm verification results',
	ENTER_PERSONAL_DETAILS: 'Enter personal details',
	ORDER_BUY_SEARCHES: 'Order searches (buy side)',
	ORDER_SELL_SEARCHES: 'Order searches (sell side)',
	COMPLETE_SELL_QUESTIONS: 'Complete sell questions',
	COMPLETE_BUY_QUESTIONS: 'Complete buy questions',
	COMPLETE_GIFTOR_QUESTIONS: 'Complete giftor questions',
	UPLOAD_GIFT_PROOF_OF_FUNDS: 'Upload buyer gift proof of funds',
	UPLOAD_FLOOR_PLAN: 'Upload floor plan',
	UPLOAD_PROTECTED_BUILDING: 'Upload protected building',
	UPLOAD_PROTECTED_TREES: 'Upload protected trees',
	UPLOAD_ALTERATIONS_USE: 'Upload alterations use',
	UPLOAD_ALTERATIONS_WINDOWS: 'Upload alterations windows',
	UPLOAD_ALTERATIONS_EXTENSION: 'Upload alterations extension',
	UPLOAD_ALTERATIONS_CONSERVATORY: 'Upload alterations conservatory',
	UPLOAD_LOFT_CONVERSION: 'Upload loft conversion',
	UPLOAD_GARAGE_CONVERSION: 'Upload garage conversion',
	UPLOAD_INTERNAL_WALLS: 'Upload internal walls',
	UPLOAD_OTHER_WORKS: 'Upload other works',
	UPLOAD_HOME_WARRANTY: 'Upload home warranty',
	UPLOAD_UNDERPINNING_WARRANTY: 'Upload underpinning warranty',
	UPLOAD_CENTRAL_HEATING_WARRANTY: 'Upload central heating warranty',
	UPLOAD_ROOFING_WARRANTY: 'Upload roofing warranty',
	UPLOAD_ELECTRICAL_WORK_WARRANTY: 'Upload electrical work warranty',
	UPLOAD_WINDOWS_WARRANTY: 'Upload windows warranty',
	UPLOAD_TIMBER_TREATMENT_WARRANTY: 'Upload timber treatment warranty',
	UPLOAD_DAMP_ROOFING_WARRANTY: 'Upload damp roofing warranty',
	UPLOAD_OTHER_WARRANTIES: 'Upload other warranties',
	UPLOAD_BUILDING_INSURANCE: 'Upload building insurance',
	UPLOAD_HOME_INSURANCE: 'Upload home insurance',
	UPLOAD_JAPANESE_KNOTWEED: 'Upload japanese knotweed',
	UPLOAD_SELL_BANK_STATEMENTS: 'Upload sell bank statements',
	UPLOAD_SELL_MORTGAGE_STATEMENT: 'Upload sell mortgage statement',
	ORDER_MANAGEMENT_PACK: 'Order management pack',
	UPLOAD_GROUND_RENT: 'Upload charge rent',
	UPLOAD_SERVICE_CHARGE: 'Upload service charge',
	UPLOAD_FREEHOLD_MANAGEMENT_COMPANY: 'Upload freehold management company',
	UPLOAD_SEWERAGE: 'Upload sewerage',
	UPLOAD_PARTY_WALL: 'Upload party wall',
	UPLOAD_EICR: 'Upload EICR',
	UPLOAD_GAS_SAFE_REGISTER: 'Upload gas safety register',
	UPLOAD_NOTICE_OF_SALE: 'Upload notice of sale',
	UPLOAD_NOTICE_ABOUT_BUILDING: 'Upload notice about building',
	UPLOAD_LEASE_TERM_CHANGES: 'Upload lease term changes',
	UPLOAD_COLLECTIVE_PURCHASE_NOTICE: 'Upload collective purchase notice',
	UPLOAD_LANDLORD_RESPONSE_TO_NOTICE: 'Upload landlord response to notice',
	UPLOAD_BUY_MORTGAGE: 'Upload buy mortgage',
	UPLOAD_BUY_REMORTGAGE: 'Upload buy remortgage',
	UPLOAD_BUY_EQUITY_RELEASE: 'Upload buy equity release',
	UPLOAD_BUY_SAVINGS: 'Upload buy savings',
	UPLOAD_BUY_GIFT: 'Upload buy gift',
	UPLOAD_BUY_INHERITANCE: 'Upload buy inheritance',
	UPLOAD_BUY_PROPERTY_SALE: 'Upload buy property sale',
	UPLOAD_BUY_PENSION: 'Upload buy pension',
	UPLOAD_BUY_GAMBLING: 'Upload buy gambling',
	UPLOAD_BUY_COMPENSATION: 'Upload buy compensation',
	UPLOAD_BUY_BANK_STATEMENTS: 'Upload buy bank statements',
	SELLER_CLIENT_CARE: 'Seller client care',
	BUYER_CLIENT_CARE: 'Buyer client care',
	OBTAIN_NEW_EPC: 'Obtain new EPC',
	TITLE_PENDING_APPLICATIONS: 'Title pending applications',
	UPLOAD_GIFT_COMPENSATION: 'Upload gift compensation',
	UPLOAD_GIFT_EQUITY_RELEASE: 'Upload gift equity release',
	UPLOAD_GIFT_GAMBLING: 'Upload gift gambling',
	UPLOAD_GIFT_INHERITANCE: 'Upload gift inheritance',
	UPLOAD_GIFT_LOAN: 'Upload gift mortgage',
	UPLOAD_GIFT_PENSION: 'Upload gift pension',
	UPLOAD_GIFT_PROPERTY_SALE: 'Upload gift property sale',
	UPLOAD_GIFT_REMORTGAGE: 'Upload gift remortgage',
	UPLOAD_GIFT_SAVINGS: 'Upload gift savings',
	UPLOAD_ELECTRICAL_BUILDING_COMPLIANCE: 'Electrical installation: Upload Building Regulations Compliance Certificate',
	UPLOAD_ELECTRICAL_SAFETY_CERTIFICATE: 'Electrical installation: Upload electrical safety certificate',
	UPLOAD_ELECTRICAL_INSURANCE: 'Electrical installation: Upload no-certificate insurance',
	UPLOAD_NAME_CHANGE_EVIDENCE: 'Upload name change evidence (ID vs Title)',
	VERIFICATION_ISSUE: 'Verification Issue',
	PENDING_VERIFICATION_CONFIRMATION: 'Pending Verification Confirmation',
	USER_WELCOME: 'User Welcome',
	USER_FEATURES_INTRODUCTION: 'User Features Introduction',
	USER_SUCCESS_STORIES: 'User Success Stories',
	USER_ENCOURAGEMENT_TO_ACTION: 'User Encouragement To Action',
	USER_EXCLUSIVE_TIPS: 'User Exclusive Tips',
	USER_FINAL_CHECK_IN: 'User Final Check In',
	UPLOAD_TENANTS_NOTICE: 'Upload Tenants Notice',
	UPLOAD_SECTION_42_NOTICE: 'Upload Section 42 Notice',
	UPLOAD_TENANTS_RESPONSE: 'Upload Tenants Response',
	UPLOAD_COLLECTIVE_PURCHASE_RESPONSE: 'Upload Collective Purchase Response',
	UPLOAD_SECTION_42_RESPONSE: 'Upload Section 42 Response',
	UPLOAD_GIFT_BANK_STATEMENTS: 'Upload Gift Bank Statements',
	UPLOAD_ELECTRICAL_BUILDING_COMPLETION: 'Upload Electrical Building Completion',
	SIGN_SELLER_FORMS: 'Sign Seller Forms',
	VIEW_SELLER_FORMS: 'View Seller Forms',
	SELL_QUOTE_SENT: 'Sell Quote Sent',
	SELL_QUOTE_ACCEPTED: 'Sell Quote Accepted',
	SELL_SEARCHES_PAID: 'Sell Searches Paid',
	SELL_SEARCHES_UPLOADED: 'Sell Searches Uploaded',
	SELL_SOLICITOR_ASSIGNED: 'Sell Solicitor Assigned',
	SELL_CCL_SENT: 'Sell CCL Sent',
	SELL_CCL_SIGNED: 'Sell CCL Signed',
	SELL_PROPERTY_LISTED: 'Sell Property Listed',
	SELL_OFFER_ACCEPTED: 'Sell Offer Accepted',
	SELL_PRICE_SET: 'Sell Price Set',
	BUY_QUOTE_SENT: 'Buy Quote Sent',
	BUY_QUOTE_ACCEPTED: 'Buy Quote Accepted',
	ACCEPT_INVITE: 'Accept Invite',
	BUY_SEARCHES_PAID: 'Buy Searches Paid',
	BUY_SOLICITOR_ASSIGNED: 'Buy Solicitor Assigned',
	BUY_CCL_SENT: 'Buy CCL Sent',
	BUY_CCL_SIGNED: 'Buy CCL Signed',
	SELL_CONTRACT_SENT: 'Sell Contract Sent',
	BUY_CONTRACT_APPROVED: 'Buy Contract Approved',
	BUY_TR1_SENT: 'Buy TR1 Sent',
	SELL_TR1_APPROVED: 'Sell TR1 Approved',
	BUY_ENQUIRIES_SENT: 'Buy Enquiries Sent',
	SELL_ENQUIRIES_RECEIVED: 'Sell Enquiries Received',
	SELL_REPLIES_SENT: 'Sell Replies Sent',
	BUY_REPLIES_RECEIVED: 'Buy Replies Received',
	BUY_ENQUIRIES_COMPLETE: 'Buy Enquiries Complete',
	EXCHANGE_DATE_AGREED: 'Exchange Date Agreed',
	COMPLETION_DATE_AGREED: 'Completion Date Agreed',
	HAS_EXCHANGED: 'Has Exchanged',
	HAS_COMPLETED: 'Has Completed',
	SELLER_DUE_DILIGENCE_FIX: 'Seller Due Diligence Fix',
	LISTING_FIX: 'Listing Fix',
	SALE_AGREED_FIX: 'Sale Agreed Fix',
	BUYER_DUE_DILIGENCE_FIX: 'Buyer Due Diligence Fix',
	EXCHANGE_FIX: 'Exchange Fix',
	COMPLETION_FIX: 'Completion Fix',
};

export const labelByLogStatus: Record<LogStatusEnum, string> = {
	FAILED: 'Failed',
	PENDING: 'Pending',
	SUCCEED: 'Succeed',
	SENT: 'Sent',
	COMPLETE: 'Complete',
	CREATED: 'Created',
	DELETED: 'Deleted',
};

export const labelByLogType: Record<LogTypeEnum, string> = { EMAIL: 'Email', JOB: 'Job', TASK: 'Task' };
