import { Box } from '@mui/material';
import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { BoxDocumentsStyle } from 'src/pages/properties/style';
import { dispatch } from 'src/redux/store';
import { getDocumentsOverviewThunk, selectDocumentsField, selectDocumentsFolderId } from 'src/redux/slices/documents';
import useCurrentDocumentsLocation from 'src/hooks/useCurrentDocumentsLocation';
import { selectProperty } from 'src/redux/slices/property';
import DocumentsBreadcrumbs from './components/DocumentsBreadcrumbs';
import Progress from '../Progress';
import { AddFolderModal, DeleteFolderModal, UpdateFolderModal } from './modals';
import DeleteDocumentModal from './modals/DeleteDocument';
import DocumentOverview from './components/DocumentOverview';

const Documents = () => {
	const { isInitialized, data: documents } = useSelector(selectDocumentsField);
	const currentFolderId = useSelector(selectDocumentsFolderId);
	const property = useSelector(selectProperty);
	const currentLocation = useCurrentDocumentsLocation();

	useEffect(() => {
		dispatch(getDocumentsOverviewThunk({ withLoading: true, location: currentLocation }));
	}, [currentFolderId, currentLocation, property.id]);

	const areDocumentsEmpty = !Object.keys(documents?.structure ?? {}).length;

	return (
		<BoxDocumentsStyle position='relative'>
			{!isInitialized && (
				<Box
					position='absolute'
					width='100%'
					height='100%'
					display='flex'
					alignItems='center'
					justifyContent='center'
					bgcolor='white'
					sx={{ opacity: 0.6 }}
					zIndex={99}
				>
					<Progress />
				</Box>
			)}
			{!!documents && !areDocumentsEmpty && (
				<>
					<DocumentsBreadcrumbs />
					<Box pt='15px' display='flex' flexDirection='column' gap='15px' height='100%'>
						<DocumentOverview document={documents.structure} />
					</Box>
				</>
			)}
			<AddFolderModal />
			<UpdateFolderModal />
			<DeleteFolderModal />
			<DeleteDocumentModal />
		</BoxDocumentsStyle>
	);
};

export default Documents;
